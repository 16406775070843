@use "@material/theme";
@use "@material/shape";
@use "@material/drawer";
@use "@material/list";
@use '@angular/material' as mat;


@import './reset';
@import './variables';
@import './functions';
@import "./placeholders";
@import "./mixins";

@import "material-components-web/material-components-web";


@import '~@ctrl/ngx-emoji-mart/picker.css';

//@import "~quill/dist/quill.core.css";
//@import "~quill/dist/quill.bubble.css";
//@import "~quill/dist/quill.snow.css";
@include mat.core();

$primary-pallete: (
    50: var(--mdc-theme-primary-50),
    100: var(--mdc-theme-primary-100),
    200: var(--mdc-theme-primary-200),
    300: var(--mdc-theme-primary-300),
    400: var(--mdc-theme-primary-400),
    500: var(--mdc-theme-primary-500),
    600: var(--mdc-theme-primary-600),
    700: var(--mdc-theme-primary-700),
    800: var(--mdc-theme-primary-800),
    900: var(--mdc-theme-primary-900),
    A100: var(--mdc-theme-primary-A100),
    A200: var(--mdc-theme-primary-A200),
    A400: var(--mdc-theme-primary-A400),
    A700: var(--mdc-theme-primary-A700),
    contrast: (
        50: var(--mdc-theme-primary-50-ct),
        100: var(--mdc-theme-primary-100-ct),
        200: var(--mdc-theme-primary-200-ct),
        300: var(--mdc-theme-primary-300-ct),
        400: var(--mdc-theme-primary-400-ct),
        500: var(--mdc-theme-primary-500-ct),
        600: var(--mdc-theme-primary-600-ct),
        700: var(--mdc-theme-primary-700-ct),
        800: var(--mdc-theme-primary-800-ct),
        900: var(--mdc-theme-primary-900-ct),
        A100: var(--mdc-theme-primary-A100-ct),
        A200: var(--mdc-theme-primary-A200-ct),
        A400: var(--mdc-theme-primary-A400-ct),
        A700: var(--mdc-theme-primary-A700-ct)
    )
);

$accent-pallete: (
    50: var(--mdc-theme-secondary-50),
    100: var(--mdc-theme-secondary-100),
    200: var(--mdc-theme-secondary-200),
    300: var(--mdc-theme-secondary-300),
    400: var(--mdc-theme-secondary-400),
    500: var(--mdc-theme-secondary-500),
    600: var(--mdc-theme-secondary-600),
    700: var(--mdc-theme-secondary-700),
    800: var(--mdc-theme-secondary-800),
    900: var(--mdc-theme-secondary-900),
    A100: var(--mdc-theme-secondary-A100),
    A200: var(--mdc-theme-secondary-A200),
    A400: var(--mdc-theme-secondary-A400),
    A700: var(--mdc-theme-secondary-A700),
    contrast: (
        50: var(--mdc-theme-secondary-50-ct),
        100: var(--mdc-theme-secondary-100-ct),
        200: var(--mdc-theme-secondary-200-ct),
        300: var(--mdc-theme-secondary-300-ct),
        400: var(--mdc-theme-secondary-400-ct),
        500: var(--mdc-theme-secondary-500-ct),
        600: var(--mdc-theme-secondary-600-ct),
        700: var(--mdc-theme-secondary-700-ct),
        800: var(--mdc-theme-secondary-800-ct),
        900: var(--mdc-theme-secondary-900-ct),
        A100: var(--mdc-theme-secondary-A100-ct),
        A200: var(--mdc-theme-secondary-A200-ct),
        A400: var(--mdc-theme-secondary-A400-ct),
        A700: var(--mdc-theme-secondary-A700-ct)
    )
);

$warn-pallete: (
    50: var(--mdc-theme-error-50),
    100: var(--mdc-theme-error-100),
    200: var(--mdc-theme-error-200),
    300: var(--mdc-theme-error-300),
    400: var(--mdc-theme-error-400),
    500: var(--mdc-theme-error-500),
    600: var(--mdc-theme-error-600),
    700: var(--mdc-theme-error-700),
    800: var(--mdc-theme-error-800),
    900: var(--mdc-theme-error-900),
    A100: var(--mdc-theme-error-A100),
    A200: var(--mdc-theme-error-A200),
    A400: var(--mdc-theme-error-A400),
    A700: var(--mdc-theme-error-A700),
    contrast: (
        50: var(--mdc-theme-error-50-ct),
        100: var(--mdc-theme-error-100-ct),
        200: var(--mdc-theme-error-200-ct),
        300: var(--mdc-theme-error-300-ct),
        400: var(--mdc-theme-error-400-ct),
        500: var(--mdc-theme-error-500-ct),
        600: var(--mdc-theme-error-600-ct),
        700: var(--mdc-theme-error-700-ct),
        800: var(--mdc-theme-error-800-ct),
        900: var(--mdc-theme-error-900-ct),
        A100: var(--mdc-theme-error-A100-ct),
        A200: var(--mdc-theme-error-A200-ct),
        A400: var(--mdc-theme-error-A400-ct),
        A700: var(--mdc-theme-error-A700-ct)
    )
);

$primary: mat.define-palette($primary-pallete);
$accent: mat.define-palette($accent-pallete);
$warn: mat.define-palette($warn-pallete);

$theme: mat.define-light-theme((
    color: (
        primary: $primary,
        accent: $accent,
        warn: $warn
    )
));

@include mat.all-component-themes($theme);


@font-face {
  font-family: 'FirefoxEmoji';
  src: url('../assets/fonts/NotoColorEmoji.ttf')
}
@font-face {
  font-family: 'MochiPop';
  src: url('../assets/fonts/MochiyPopPOne-Regular.ttf')
}
@font-face {
  font-family: 'Creepster';
  src: url('../assets/fonts/Creepster-Regular.ttf')
}

strong{
  font-weight: 700 !important;
}

em{
  font-style: italic !important;
}
html, body{
    height: 100%;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: var(--default-font-size);
    background-color: var(--mat-white-color);
    text-size-adjust: 100%;

    .mat-form-field{
        .mat-form-field-infix, .mat-form-field-infix{
            width: 100%;
        }
    }

    @media(max-width: $mobile){
        font-size: var(--small-font-size);
    }
}

noscript{
    text-align: center;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
    background-color: var(--mdc-theme-primary);
    font-size: 2rem !important;
    color: var(--mdc-theme-on-secondary);
    font-family: 'Roboto', sans-serif !important;
    margin: 0 !important;
    line-height: 1.25;

    @extend %no-selection;
}

*{
    //-webkit-tap-highlight-color: transparent;
    outline: none;
    scroll-behavior: smooth;
    hyphens: auto;
    word-break: break-word;

    &::selection{
        color: var(--mdc-theme-on-primary);
        background-color: rgba(var(--mdc-theme-primary-rgb), .86);
    }

    &:not(body):not(html):not(mat-sidenav-content):not(.sign-in):not(main){
        &::-webkit-scrollbar{
            height: var(--mat-scroll-size);
            width: var(--mat-scroll-size);
            background: transparent;
        }

        &:hover::-webkit-scrollbar-thumb{
            background:  rgba(0, 0, 0, 0.26);
        }

        &::-webkit-scrollbar-track {
            margin: 0;
        }

        &::-webkit-scrollbar-thumb{
            border: none;
            box-shadow: none;
            background:  rgba(0, 0, 0, 0.18);
            border-radius: 4px;
            min-height: 40px;
        }
    }
}

.material-icons, .material-icons-filled {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

.material-icons{
    font-family: 'Material Icons Outlined', sans-serif;
}

.material-icons-filled {
    font-family: 'Material Icons', sans-serif;
}

.mat-background-primary-color{
    background-color: var(--mdc-theme-primary) !important;
}

mat-sidenav-content{
    overflow-x: hidden !important;
}

.mat-body p, .mat-body-1 p, .mat-typography p{
    margin: 0;
}

.mat-dialog-content{
    box-sizing: border-box;

    @include dialog-height(35vh, 300px, true);
    @include dialog-height(50vh, 400px);
    @include dialog-height(55vh, 500px);
    @include dialog-height(55vh, 500px);
    @include dialog-height(60vh, 600px);
    @include dialog-height(65vh, 700px);
    @include dialog-height(70vh, 800px);
}

.mat-dialog-actions {
    min-height: 0 !important;
}

mat-dialog-container{
    margin: var(--mat-small-space-dialog) !important;

    @media(max-width: $tablet){
        padding: var(--mat-small-space-dialog) !important;
    }
}

.mat-radio-label .mat-radio-label-content, mat-label, label, .mat-tooltip-panel, .cdk-overlay-container, .cdk-overlay-connected-position-bounding-box{
    @extend %no-selection;
}

[mat-sort-header].cdk-keyboard-focused .mat-sort-header-container, [mat-sort-header].cdk-program-focused .mat-sort-header-container{
    border-bottom: 0 solid transparent !important;
}

:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after,
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after{
    background-color: transparent !important;
}

.mat-vertical-content {
    padding: var(--step-space) !important;
}

.mat-step-header{
    border-radius: var(--mat-border-radius) !important;
}

@media(max-width: 514px){
    .mat-paginator-container{
        padding-top: 8px !important;

        .mat-paginator-range-label{
            margin: 0 16px 0 24px !important;
            white-space: nowrap !important;
        }
    }
}

.mat-loading .mat-button-wrapper {
    visibility: hidden;
}

.mat-loading .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

.mat-standard-chip{
    box-sizing: content-box !important;
    min-height: auto !important;
    height: auto !important;
}

.mat-menu-panel{
    min-height: 0 !important;
}

.ngx-mat-color-canvas .color-canvas-row:nth-of-type(2), .ngx-mat-color-collection, .ngx-mat-color-canvas .color-canvas-row .zone-inputs{
    display: none !important;
}

.ngx-mat-color-canvas .color-canvas-row .zone-strip{
    margin-left: 16px !important;
}

.ngx-mat-color-canvas .color-canvas-row .zone-canvas .zone-block, .ngx-mat-color-canvas .color-canvas-row .zone-strip{
    border: var(--border);
    border-radius: var(--mat-border-radius);
}

.color-canvas-row{
    margin-bottom: 0 !important;
}

input, mat-select-trigger, textarea{
    line-height: 1 !important;
}

.mat-list-base .mat-list-item .mat-list-item-content{
    padding: 0 0 0 24px !important;
}

.mat-tooltip{
    text-align: center !important;
}

.mat-sort-header-content{
    text-align: left !important;
}

.mat-menu-item .mat-icon {
    margin-right: 8px !important;
}

.mat-menu-content{
    padding: 0 !important;
}

.mat-menu-item{
    display: flex !important;
    align-items: center !important;
}

.mat-drawer-inner-container{
    overflow: hidden !important;
}

.mat-form-field.select .mat-icon{
    margin-left: .5em !important;
}

img{
    pointer-events: none !important;
}

.ql-container {
    border: var(--border) !important;
    border-top: 0 !important;
    border-radius: 0 0 var(--mat-border-radius) var(--mat-border-radius) !important;
}

.ql-tooltip, input[data-link]{
    border: var(--border) !important;
    border-radius: var(--mat-border-radius) !important;
    color: var(--mat-primary-color) !important;
}

.ql-tooltip a.ql-action::after{
    margin-left: $ql-space !important;
    border-right: 0 !important;
}

.ql-remove{
    border-left: var(--border) !important;
    margin-left: 0 !important;
    padding-left: $ql-space;
}

.ql-editor{
    *{
        font: var(--ql-editor-default-font);
    }

    ul, ol{
        padding: 0 !important;
    }
}

.ql-editor, .ql-toolbar, .ql-tooltip, input[data-link]{
    padding: $ql-space !important;
}

.ql-toolbar{
    border: var(--border) !important;
    border-radius: var(--mat-border-radius) var(--mat-border-radius) 0 0 !important;
}

.ql-editor.ql-blank::before{
    font-style: revert !important;
    right: $ql-space !important;
    left: $ql-space !important;
}

.ql-action, .ql-remove{
    color: var(--mdc-theme-primary) !important;
}

.ql-tooltip a.ql-remove::before{
    margin-left: 0 !important;
}

.ql-stroke{
    stroke: var(--mat-primary-color) !important;
    transition: stroke var(--time-transition) !important;
}

.ql-fill{
    fill: var(--mat-primary-color) !important;
    transition: stroke var(--time-transition) !important;
}

.ql-formats button:hover, .ql-formats button:focus, .ql-formats button:active,
.ql-picker-label:hover, .ql-picker-label:focus, .ql-picker-label:active,
.ql-picker-item:hover, .ql-picker-item:focus, .ql-picker-item:active{
    .ql-stroke{
        stroke: var(--mdc-theme-primary) !important;
    }

    .ql-fill{
        fill: var(--mdc-theme-primary) !important;
    }
}

.ql-active, .ql-selected{
    .ql-stroke{
        stroke: var(--mdc-theme-primary) !important;
    }

    .ql-fill{
        fill: var(--mdc-theme-primary) !important;
    }
}

.ql-editor, .ql-tooltip{
    a{
        &:not(.ql-remove):not(.ql-action){
            @include link-style();

            &.ql-preview{
                vertical-align: middle;
            }
        }
    }
}


